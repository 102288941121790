import React, { Component, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import "../../scss/style.scss";
import { ClearStorage } from "../redux/ClearStorage";

// Containers
const DefaultLayout = React.lazy(() => import("../../layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("../../views/pages/Guest/login/Login"));
// const Register = React.lazy(() => import('../../views/pages/Guest/register/Register'))
const Page404 = React.lazy(() =>
  import("../../views/pages/Error/page404/Page404")
);
const Page500 = React.lazy(() =>
  import("../../views/pages/Error/page500/Page500")
);
const FileView = React.lazy(() =>
  import("../../views/pages/Guest/viewer/fileView")
);
const CertificateView = React.lazy(() =>
  import("../../views/pages/Guest/viewer/certificateView")
);
const ViewInvoice = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewInvoice")
);
const ViewDetailedProfitLoss = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewDetailedProfitLoss")
);
const ViewDetailedBalanceSheet = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewDetailedBalanceSheet")
);
const ViewAccountPayableReport = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewAccountPayableReport")
);
const ViewAccountReceivableReport = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewAccountReceivableReport")
);
const ViewAgeingReport = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewAgeingReport")
);
const ViewTrialBalance = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewTrialBalance")
);
const ViewInvoiceReport = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewInvoiceReport")
);
const ViewLedgerReport = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewLedgerReport")
);
const ViewSupplierStatementOfAccount = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewSupplierStatementOfAccount")
);
const ViewCustomerStatementOfAccount = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewCustomerStatementOfAccount")
);
const ViewReceiptVoucher = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewReceiptVoucher")
);
const ViewCashPaymentVoucher = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewCashPaymentVoucher")
);
const ViewBankPaymentVoucher = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewBankPaymentVoucher")
);
const ViewPurchaseInvoice = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewPurchaseInvoice")
);
const ViewDebitNote = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewDebitNote")
);
const ViewJournalVoucher = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewJournalVoucher")
);
const ViewCreditNote = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewCreditNote")
);
const ViewUser = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewUser")
);
const ViewQuotation = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewQuotation")
);
const ViewSurveyorsUsers = React.lazy(() =>
  import("../../views/pages/Guest/viewer/viewSurveyorsUsers")
);

const authNavigation = () => {
  const { isUserLogin } = useSelector((state) => state.login);
  const GuestUser = () => {
    return (
      <Routes>
        <Route exact path="/" name="Login Page" element={<Login />} />
        <Route exact path="/login" name="Login Page" element={<Login />} />
        {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
        <Route exact path="/404" name="Page Not Found" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route
          exact
          path="/fileView/:baseCode"
          name="View Screen"
          element={<FileView />}
        />
        <Route
          exact
          path="/certificateView"
          name="View Certificate"
          element={<CertificateView />}
        />
        <Route
          exact
          path="/viewDetailedProfitLoss/:reportNo"
          name="View Detailed Profit & Loss"
          element={<ViewDetailedProfitLoss />}
        />
        <Route
          exact
          path="/viewDetailedBalanceSheet/:reportNo"
          name="View Detailed BalanceSheet"
          element={<ViewDetailedBalanceSheet />}
        />
        <Route
          exact
          path="/viewAccountPayableReport/:reportNo"
          name="View Account Payable Report"
          element={<ViewAccountPayableReport />}
        />
        <Route
          exact
          path="/viewAccountReceivableReport/:reportNo"
          name="View Account Receivable Report"
          element={<ViewAccountReceivableReport />}
        />
        <Route
          exact
          path="/viewTrialBalance/:reportNo"
          name="View Trial Balance"
          element={<ViewTrialBalance />}
        />
        <Route
          exact
          path="/viewAgeingReport/:reportNo"
          name="View Ageing Report"
          element={<ViewAgeingReport />}
        />
        <Route
          exact
          path="/viewInvoiceReport/:reportNo"
          name="View Invoice Report"
          element={<ViewInvoiceReport />}
        />
        <Route
          exact
          path="/viewLedgerReport/:reportNo"
          name="View Ledger Report"
          element={<ViewLedgerReport />}
        />
        <Route
          exact
          path="/viewSupplierStatementOfAccount/:statementNo"
          name="View Supplier Statement Of Account"
          element={<ViewSupplierStatementOfAccount />}
        />
        <Route
          exact
          path="/viewCustomerStatementOfAccount/:statementNo"
          name="View Customer Statement Of Account"
          element={<ViewCustomerStatementOfAccount />}
        />
        <Route
          exact
          path="/viewInvoice/:invoiceNo"
          name="View Invoice"
          element={<ViewInvoice />}
        />
        <Route
          exact
          path="/viewReceiptVoucher/:type/:voucherId"
          name="View Receipt Voucher"
          element={<ViewReceiptVoucher />}
        />
        <Route
          exact
          path="/viewCashPaymentVoucher/:voucherId"
          name="View Cash Payment Voucher"
          element={<ViewCashPaymentVoucher />}
        />
        <Route
          exact
          path="/viewBankPaymentVoucher/:voucherId"
          name="View Bank Payment Voucher"
          element={<ViewBankPaymentVoucher />}
        />
        <Route
          exact
          path="/viewJournalVoucher/:journalVoucherNo"
          name="View Journal Voucher"
          element={<ViewJournalVoucher />}
        />
        <Route
          exact
          path="/viewDebitNote/:debitNoteNo"
          name="View Debit Note"
          element={<ViewDebitNote />}
        />
        <Route
          exact
          path="/viewCreditNote/:creditNoteNo"
          name="View Credit Note"
          element={<ViewCreditNote />}
        />
        <Route
          exact
          path="/viewPurchaseInvoice/:purchaseInvoiceNo"
          name="View Purchase Invoice"
          element={<ViewPurchaseInvoice />}
        />
        <Route
          exact
          path="/viewUser/:userType/:userId"
          name="View User"
          element={<ViewUser />}
        />
        <Route
          exact
          path="/viewQuotation/:quotationId"
          name="View Quotation"
          element={<ViewQuotation />}
        />
        <Route
          exact
          path="/viewSurveyorsUsers/:userType/:userId"
          name="View SurveyorsUsers"
          element={<ViewSurveyorsUsers />}
        />
        <Route path="/*" name="Login Page" element={<Login />} />
      </Routes>
    );
  };

  const AuthUser = () => {
    return (
      <Routes>
        <Route path="*" name="Home" element={<DefaultLayout />} />
        <Route
          exact
          path="/fileView/:baseCode"
          name="View Screen"
          element={<FileView />}
        />
        <Route
          exact
          path="/certificateView"
          name="View Certificate"
          element={<CertificateView />}
        />
        <Route
          exact
          path="/viewInvoice/:invoiceNo"
          name="View Invoice"
          element={<ViewInvoice />}
        />
        <Route
          exact
          path="/viewDetailedProfitLoss/:reportNo"
          name="View Detailed Profit & Loss"
          element={<ViewDetailedProfitLoss />}
        />
        <Route
          exact
          path="/viewDetailedBalanceSheet/:reportNo"
          name="View Detailed BalanceSheet"
          element={<ViewDetailedBalanceSheet />}
        />
        <Route
          exact
          path="/viewAccountPayableReport/:reportNo"
          name="View Account Payable Report"
          element={<ViewAccountPayableReport />}
        />
        <Route
          exact
          path="/viewAccountReceivableReport/:reportNo"
          name="View Account Receivable Report"
          element={<ViewAccountReceivableReport />}
        />
        <Route
          exact
          path="/viewAgeingReport/:reportNo"
          name="View Ageing Report"
          element={<ViewAgeingReport />}
        />
        <Route
          exact
          path="/viewTrialBalance/:reportNo"
          name="View Trial Balance"
          element={<ViewTrialBalance />}
        />
        <Route
          exact
          path="/viewInvoiceReport/:reportNo"
          name="View Invoice Report"
          element={<ViewInvoiceReport />}
        />
        <Route
          exact
          path="/viewLedgerReport/:reportNo"
          name="View Ledger Report"
          element={<ViewLedgerReport />}
        />
        <Route
          exact
          path="/viewSupplierStatementOfAccount/:statementNo"
          name="View Supplier Statement Of Account"
          element={<ViewSupplierStatementOfAccount />}
        />
        <Route
          exact
          path="/viewCustomerStatementOfAccount/:statementNo"
          name="View Customer Statement Of Account"
          element={<ViewCustomerStatementOfAccount />}
        />
        <Route
          exact
          path="/viewReceiptVoucher/:type/:voucherId"
          name="View Receipt Voucher"
          element={<ViewReceiptVoucher />}
        />
        <Route
          exact
          path="/viewCashPaymentVoucher/:voucherId"
          name="View Cash Payment Voucher"
          element={<ViewCashPaymentVoucher />}
        />
        <Route
          exact
          path="/viewBankPaymentVoucher/:voucherId"
          name="View Bank Payment Voucher"
          element={<ViewBankPaymentVoucher />}
        />
        <Route
          exact
          path="/viewPurchaseInvoice/:purchaseInvoiceNo"
          name="View Purchase Invoice"
          element={<ViewPurchaseInvoice />}
        />
        <Route
          exact
          path="/viewDebitNote/:debitNoteNo"
          name="View Debit Note"
          element={<ViewDebitNote />}
        />
        <Route
          exact
          path="/viewJournalVoucher/:journalVoucherNo"
          name="View Journal Voucher"
          element={<ViewJournalVoucher />}
        />
        <Route
          exact
          path="/viewCreditNote/:creditNoteNo"
          name="View Credit Note"
          element={<ViewCreditNote />}
        />
        <Route
          exact
          path="/viewUser/:userType/:userId"
          name="View User"
          element={<ViewUser />}
        />
        <Route
          exact
          path="/viewQuotation/:quotationId"
          name="View Quotation"
          element={<ViewQuotation />}
        />
        <Route
          exact
          path="/viewSurveyorsUsers/:userType/:userId"
          name="View SurveyorsUsers"
          element={<ViewSurveyorsUsers />}
        />
      </Routes>
    );
  };

  return (
    <>
      {isUserLogin ? (
        <>
          <AuthUser />
          <ClearStorage />
          {/* For Test (Remove it while build) */}
        </>
      ) : (
        <GuestUser />
      )}
    </>
  );
};

export default authNavigation;
